import jQueryBridget from 'jquery-bridget';

import Masonry from 'masonry-layout';

// make Masonry a jQuery plugin

jQueryBridget( 'masonry', Masonry, $ );



var imagesLoaded = require('imagesloaded');



$(document).ready(function(){

  $('.header-slider').slick({

    slideToShow: 1,

    dots:true, 

    arrows: false,

    autoplay: true,

    fade: true,

    speed: 1000,

    autoplaySpeed: 5000

  });



    $('.news-slider').slick({

    slidesToShow: 3,

    slidesToScroll: 1,

    arrows: true,

    prevArrow: '<i class="fas fa-chevron-left prev-arrow"></i>',

    nextArrow: '<i class="fas fa-chevron-right next-arrow"></i>',

    adaptiveHeight: true,

      responsive: [

    {

      breakpoint: 1640,

      settings: {

        slidesToShow: 2,

        slidesToScroll: 1,

        infinite: true

      }

    },

    {

      breakpoint: 990,

      settings: {

        slidesToShow: 1,

        slidesToScroll: 1

      }

    }

    // You can unslick at a given breakpoint now by adding:

    // settings: "unslick"

    // instead of a settings object

  ]



    

    });

    



  var $realisations_gallery = $('.gallery-grid').imagesLoaded(function() {

      $realisations_gallery.masonry({

          itemSelector: '.gallery-item',

          percentPosition: true

      });

  });



  $('.menu-bar').click(function() {

    $('nav').toggleClass('show');

    $('.menu-bar i').toggleClass('fa-bars fa-times');

  })

   $('nav a').click(function() {

    $('nav').toggleClass('show');

     $('.menu-bar i').toggleClass('fa-bars fa-times');

  });



    $("a").on('click', function(event) {



    // Make sure this.hash has a value before overriding default behavior

    if (this.hash !== "") {

      // Prevent default anchor click behavior

      event.preventDefault();



      // Store hash

      var hash = this.hash;



      // Using jQuery's animate() method to add smooth page scroll

      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area

      $('html, body').animate({

        scrollTop: $(hash).offset().top

      }, 800, function(){



        // Add hash (#) to URL when done scrolling (default click behavior)

        window.location.hash = hash;

      });

    } // End if

  });





  // Action au click dans les icones contact

  $('.icon-item').on('click',function(e){

    $('.icon-item span').removeClass('showme');

    $(this).find('span').toggleClass('showme');

  });

  $(document).mouseup(function (e) { 

      if ($(e.target).closest(".icon-item").length 

                  === 0) { 

          $(".icon-item span").removeClass('showme');

      }

  });



  // lire la suite présentation

  $('#presentation .cta-btn').on('click',function(e){

    e.preventDefault();

    if($('#presentation').hasClass('show')){

      $(this).html('Lire la suite <i class="fas fa-arrow-down"></i>');    

    }else{

      $(this).html('Fermer <i class="fas fa-arrow-up"></i>');  

    }

    $(this).parent().toggleClass('show');

    $('html, body').animate({

      scrollTop: $(this).parent().offset().top

    }, 800);

  });

  // lire la suite présentation

  $('#clayton .cta-btn').on('click',function(e){

    e.preventDefault();

    if($('#clayton .blocktext').hasClass('show')){

      $(this).html('Lire la suite <i class="fas fa-arrow-down"></i>');    

    }else{

      $(this).html('Fermer <i class="fas fa-arrow-up"></i>');  

    }

    $(this).parent().toggleClass('show');

    $('html, body').animate({

      scrollTop: $(this).parent().offset().top

    }, 800);

  });

});