
require('./css/jquery.fancybox.css');
require('./sass/style.scss');

// Feed

require('./feed/resources/sass/styles.scss');

require('./feed/resources/js/jquery-global');
// require('popper.js');
// require('bootstrap');
require('./feed/resources/js/jquery-template');

// require('./resources/js/lwsreviews-global');
require('./feed/resources/js/lwsreviews-feed');

require('./script/jquery.fancybox.js');
require('./script/slick.js');
require('./script/script.js');